.mySwiper{
  margin: 0% 6%;
  height: 65vh;
}
.card_img{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

}
.S_container {
  width: 60%;
  margin: auto;
}

.S_cards-S_container {
  display: flex;
 max-height: 500px;


  color: #333;
  /* background: #eee; */
  /* padding: 1rem; */
  /* box-shadow: 0 0 1rem #000 inset; */
  overflow-x: auto;
  position: relative;
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.S_card {
  flex: 1 0 250px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  /* background: #cdc0c0; */
  margin: 1rem;
  overflow: hidden;
  
  cursor: pointer;
  transition: all 250ms ease-in-out;
  width: 25vw;
  
  img {
    width: max-content;
    height: 250px;
    object-fit: center;
  }
  
  &:hover {

    transform: translateY(-3px);
  }
}

.S_card-content {
  padding: 0.5rem;
}
.S_head{
  font-size: 1.1rem;
}
@media screen and (max-width: 625px) {
  .S_container {
    width: 100%
  }
  
  .S_cards-S_container {
    padding: .5rem;
  }
  
  .S_card {
    margin: .5rem;
  }
}



@media screen and (max-width: 500px) {

  .S_card {
    width:80vw;
    height: 52vh;
}
.card_img{
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: 40vh;
 

}
.card_img img{
  max-width: 70vw;
 max-height: 40vh;

}
}


/* new swiper code */

/* @media (min-width: 1440px) {
  html {
    zoom: 1.5;
  }
}

@media (min-width: 2560px) {
  html {
    zoom: 1.7;
  }
}

@media (min-width: 3860px) {
  html {
    zoom: 2.5;
  }
} */

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

body {
  font-size: 1.6rem;
  background: var(--bg);
}

.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

.swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 0.5rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(84, 82, 82, 0.5));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #000000;

}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 2rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}


@media (max-width:360px) and (min-width:318px) {
  .mySwiper{
    margin: 0% 6.9%;

  }
  .swiperslide{
  margin-right: 0 !important;
  }
  
}