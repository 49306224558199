.big-feature-section {
    padding: 30px 80px 0;
    margin-top: 0px;
  
    padding-bottom: 2em;
  }
  
  .big-feature-container {
    gap: 30px;
    padding: auto;
    border-radius: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  }
  
  #second-big-feature {
    flex-direction: row-reverse;
  }
  
  .event-feature-img img {
    width: 40vw;
    height: 60vh;
  
    padding: 0;
  }
  
  .event-feature-desc {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 60vh;
  }

  .event-feature-desc h2{
    font-size: 1.2rem;
    font-weight: 500;

  }
  .feature-desc p{
  color: var(--e-global-color-ddb3226);
  font-size: 1rem;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  
  }
  @media screen and (max-width: 456px){
    .big-feature-section {
        padding: 18px 20px 0;
      
      }
    .big-feature-container, #second-big-feature {
      flex-direction: column;
      width: 80vw;
      
  }
  .event-feature-img img {
    width: 80vw;
    height: 40vh;
  
    padding: 0;
  }
  .event-feature-desc {
  
    width: 100%;
    height: fit-content;
  }
  .event-feature-desc p{
    font-size: 0.6rem;

  }
  
  
  } 
  @media screen and (max-width: 790px){
    .big-feature-container, #second-big-feature {
      flex-direction: column;
  }
  .event-feature-img img {
    width: 60vw;
    height: 40vh;
  
    padding: 0;
  }
  .event-feature-desc {
  
    width: 100%;
    height: fit-content;
  }
  .event-feature-desc p{
    font-size: 0.8rem;

  }
  
  
  } 
  @media screen and (max-width: 1024px){
    .event-feature-desc p{
        font-size: 0.8rem;
    
      }
      .event-feature-desc h2{
        font-size: 1.2rem;
        font-weight: 500;
    
      }
  }