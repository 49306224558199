.about-banner h1 {
  font-size: 50px;
  font-weight: 700;
}
.about p {
  font-size: 22px;
  color: #77838f;
  line-height: 1.7;
}
.count-section h3 {
  font-size: 65px;
  font-weight: 600;
}
