.tabdata {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.nav.nav-tabs {
  box-shadow: 0 3px 6px 0 rgb(140 152 164 / 25%);
  width: 80%;
  display: flex;
  justify-content: center;
  flex: 1 1 1;
}
.nav.nav-tabs a {
  flex: 1;
  text-align: center;
  color: #77838f;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #fff;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:active {
  border-color: #fff;
}
.nav-tabs .nav-link.active {
  border-bottom-color: #1b51a0;
  color: #1b51a0;
}
.nav.nav-tabs a svg {
  color: #1b51a0;
  font-size: 30px;
}

.text-info-section .number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: rgba(55, 125, 255, 0.1);
  color: #1b51a0;
  border-radius: 50%;
}
.text-info-section h4 {
  color: #1b51a0;
}
.text-info-section p {
  color: #77838f;
}
.service-cards {
  background: #f8fafd;
}
.service-cards .card {
  border: 0;
}

.pricing-btn {
  text-decoration: none !important;
  color: #1b51a0;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
}
