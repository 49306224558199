@tailwind base;
@tailwind components;
@tailwind utilities;


.slick-slide > div {
  margin: 0 10px;
}




h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

.section_padding {
  padding: 5em 0;
}
@media (min-width: 992px) {
  .width-large-70 {
    width: 75% !important;
  }
}
.banner-head {
  font-size: 60px;
  font-weight: 300;
  margin-bottom: 25px;
}
.hero-banner h2 {
  font-size: 68px;
}
p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #5f6368;
  @media (min-width:200px) and (max-width:400px) {
    font-size: 10px;
  }
}
.head-color {
  color: #0d6efd;
  font-weight: 600;
}
.btn-animation {
  box-shadow: 0 4px 11px rgb(55 125 255 / 35%);
}

.btn-animation:hover {
  transform: translateY(-3px);
  transition: 0.3s ease-in-out;
}

footer {
  background: #1b51a0;
  color: #fff !important;
}
footer p {
  color: #fff !important;
}
footer a {
  text-decoration: none !important;
  color: #fff;
}
footer .social-links {
  display: flex;
  padding: 0;
}
footer .social-links li a {
  height: 35px;
  width: 35px;
  background: #fff;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #0d6efd;
}
@media (max-width: 768px) {
   .banner-head {
    font-size: 1.4rem !important;
  }
  .hero-banner h2 {
    font-size: 1.5rem !important;
  }
  .hero-banner .hero-banner .btn {
    margin-bottom: 10px;
  }
  .pricing-btn {
    display: block;
    text-align: center;
  }
}
.text-link-with-arrow {
  font-size: 18px;
  font-weight: 600;
  transition: 0.5s all ease-in-out;
}
.text-link-with-arrow:hover .arrow {
  margin-left: 8px;
}
.text-link-with-arrow .text {
  margin-right: 20px;
}
.get-started-bar {
  font-size: 20px;
}


